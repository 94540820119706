import { IAuthContext } from "../../auth";
import {
	GeneratedPubMessage,
	LinkedImportOrExportMpanS0020Request,
	RelatedMpanS0020Request,
	MpanRelationshipProcessPageResponse,
	MpanRelationshipProcessResponse,
} from "../../models/camunda";
import {
	InboundFlowTestPagedResponse,
	InboundFlowTestRequest,
	InboundFlowType,
	MhhsSpecVersion,
} from "../../models/camunda/inbound-flows-testing";
import { baseRequest } from "./base-request";

export const addLinkedImportOrExportMpanS0020Request = async (
	authContext: IAuthContext,
	s0020: LinkedImportOrExportMpanS0020Request
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/flows/s0020",
		s0020,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const addRelatedMpanS0020Request = async (
	authContext: IAuthContext,
	s0020: RelatedMpanS0020Request
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/flows/s0020",
		s0020,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const closeException = async (
	authContext: IAuthContext,
	exceptionId: string
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"PUT",
		`v1/flows/s0020/exceptions/${exceptionId}/close`,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const resolveLinkedImportOrExportMpanException = async (
	authContext: IAuthContext,
	exceptionId: string,
	s0020: LinkedImportOrExportMpanS0020Request
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"PUT",
		`v1/flows/s0020/exceptions/${exceptionId}/resolve`,
		s0020,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const resolveRelatedMpanException = async (
	authContext: IAuthContext,
	exceptionId: string,
	s0020: RelatedMpanS0020Request
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"PUT",
		`v1/flows/s0020/exceptions/${exceptionId}/resolve`,
		s0020,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const getMpanRelationshipProcesses = async (
	authContext: IAuthContext,
	pageSize: number = 5,
	searchAfter?: number[]
): Promise<MpanRelationshipProcessResponse> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/search/process-instances",
		{
			size: pageSize,
			bpmnProcessId: "MpanRelationshipUpdate",
			searchAfter: searchAfter,
		},
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const getMpanRelationshipProcess = async (
	authContext: IAuthContext,
	key: number
): Promise<MpanRelationshipProcessPageResponse> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/search/process-instances",
		{
			bpmnProcessId: "MpanRelationshipUpdate",
			key: key,
			loadFlowLog: true,
			loadBpmn: true,
		},
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const getGeneratedInboundFlow = async (
	authContext: IAuthContext,
	mhhsSpecVersion: MhhsSpecVersion,
	flow: InboundFlowType
): Promise<GeneratedPubMessage> => {
	const res = await baseRequest(
		authContext,
		"GET",
		"v1/flows/inbound/message/" + mhhsSpecVersion,
		undefined,
		{
			flow: flow,
		}
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const addInboundFlowTestRequest = async (
	authContext: IAuthContext,
	inboundFlowRequest: InboundFlowTestRequest
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/flows/inbound",
		inboundFlowRequest,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const getInboundFlowTests = async (
	authContext: IAuthContext,
	pageSize: number = 5,
	searchAfter?: number[]
): Promise<InboundFlowTestPagedResponse> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/search/process-instances",
		{
			size: pageSize,
			bpmnProcessId: "InboundFlowTest",
			searchAfter: searchAfter,
		},
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const getInboundFlowTest = async (
	authContext: IAuthContext,
	key: number
): Promise<InboundFlowTestPagedResponse> => {
	const res = await baseRequest(
		authContext,
		"POST",
		"v1/search/process-instances",
		{
			bpmnProcessId: "InboundFlowTest",
			key: key,
			loadFlowLog: true,
			loadBpmn: true,
		},
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

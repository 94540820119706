import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	alpha,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { Pub } from "../../models/camunda";
import {
	CamundaProcessStatus,
	MpanRelationshipProcessPageResponse,
	MpanRelationshipProcessResponse,
	MpanRelationshipProcessState,
} from "../../models/camunda/mpan-relationship-process";
import { formatJavaStyleDateString } from "../../utilities";
import { MeteringPointRelationshipUpdateRequest } from "../../models/camunda/mpan-relationship-update-request";

interface IProps {
	response: MpanRelationshipProcessPageResponse;
}

const highlightStatus = (status: string) => {
	if (status == CamundaProcessStatus.ACTIVE.valueOf()) {
		return (
			<span style={{ fontWeight: "bold", color: "ORANGE" }}>{status}</span>
		);
	} else if (status == CamundaProcessStatus.INCIDENT.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "RED" }}>{status}</span>;
	} else if (status == CamundaProcessStatus.COMPLETED.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GREEN" }}>{status}</span>;
	} else if (status == CamundaProcessStatus.CANCELED.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{status}</span>;
	}
};

const highlightState = (state: string) => {
	if (state === MpanRelationshipProcessState.Accepted.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GREEN" }}>{state}</span>;
	} else if (state === MpanRelationshipProcessState.AutoClosed.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{state}</span>;
	} else if (state === MpanRelationshipProcessState.Closed.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{state}</span>;
	} else if (state === MpanRelationshipProcessState.ActionRequired.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "RED" }}>{state}</span>;
	} else if (state === MpanRelationshipProcessState.WaitingPub020.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "ORANGE" }}>{state}</span>;
	} else {
		return <span style={{ fontWeight: "bold", color: "BLACK" }}>{state}</span>;
	}
};

export const MpanRelationshipProcessesTable: React.FC<IProps> = (
	props: IProps
) => {
	const theme = useTheme();

	const navigate = useNavigate();

	const resolvePrincipalMPANFromPub020 = (
		response: MpanRelationshipProcessResponse
	): string => {
		if (response.variables.inboundPUB020) {
			try {
				const pub020 = JSON.parse(response.variables.inboundPUB020) as Pub;
				if (pub020.CommonBlock?.M1?.principalMPAN) {
					return pub020.CommonBlock?.M1?.principalMPAN;
				} else {
					return "";
				}
			} catch (error) {
				return "";
			}
		} else {
			return "";
		}
	};

	const resolvePrincipalMPANFromS0020Request = (
		response: MpanRelationshipProcessResponse
	): string => {
		if (response.variables.newMeteringPointRelationshipUpdateRequest) {
			try {
				const request = JSON.parse(
					response.variables.newMeteringPointRelationshipUpdateRequest
				) as MeteringPointRelationshipUpdateRequest;
				return request.principalMpan;
			} catch (error) {
				return "";
			}
		} else {
			return "";
		}
	};

	const resolvePrincipalMPAN = (
		process: MpanRelationshipProcessResponse
	): string => {
		const mpanFromPub020 = resolvePrincipalMPANFromPub020(process);
		const mpanFromS020 = resolvePrincipalMPANFromS0020Request(process);
		if (mpanFromPub020 == "") {
			return mpanFromS020;
		} else {
			return mpanFromPub020;
		}
	};

	const goToProcessDetails = (key: number) => {
		navigate("/mpan-relationship-processes/" + key);
	};
	return (
		<TableContainer>
			<Table size="small" data-cy="mpan-relationship-processes-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell data-cy="mpan-relationship-cell-id">ID</TableCell>
						<TableCell data-cy="mpan-relationship-cell-submitted">
							Started At
						</TableCell>
						<TableCell data-cy="mpan-relationship-cell-principal-mpan">
							Principal MPAN
						</TableCell>
						<TableCell data-cy="mpan-relationship-cell-result">
							Status
						</TableCell>
						<TableCell data-cy="mpan-relationship-cell-state">State</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.response.items.map(
						(result: MpanRelationshipProcessResponse) => (
							<TableRow
								key={result.key}
								data-cy={`flow-row-${result.key}`}
								sx={{
									"&:hover": {
										cursor: "pointer",
										backgroundColor: alpha(
											theme.palette.talos.colour.grey.dark,
											0.2
										),
									},
								}}
								onClick={() => goToProcessDetails(result.key)}
							>
								<TableCell>{result.key}</TableCell>
								<TableCell>
									{formatJavaStyleDateString(
										new Date(result.startDate).toISOString(),
										true
									)}
								</TableCell>
								<TableCell>{resolvePrincipalMPAN(result)}</TableCell>
								<TableCell sx={{ minWidth: 120 }}>
									{highlightStatus(result.state)}
								</TableCell>
								<TableCell sx={{ minWidth: 150 }}>
									{highlightState(result.currentEvent)}
								</TableCell>
							</TableRow>
						)
					)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

import React from "react";

import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	alpha,
	useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import {
	InboundFlowTestResponse,
	InboundFlowTestPagedResponse,
	InboundFlowTestingProcessState,
} from "../../models/camunda/inbound-flows-testing";
import { formatJavaStyleDateString } from "../../utilities/string-fns";
import { CamundaProcessStatus } from "../../models/camunda";

interface IProps {
	response: InboundFlowTestPagedResponse;
}

const unwrapString = (value?: string) => {
	return value ? value.replaceAll('"', "") : "";
};

const highlightStatus = (status: string) => {
	if (status == CamundaProcessStatus.ACTIVE.valueOf()) {
		return (
			<span style={{ fontWeight: "bold", color: "ORANGE" }}>{status}</span>
		);
	} else if (status == CamundaProcessStatus.INCIDENT.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "RED" }}>{status}</span>;
	} else if (status == CamundaProcessStatus.COMPLETED.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GREEN" }}>{status}</span>;
	} else if (status == CamundaProcessStatus.CANCELED.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{status}</span>;
	}
};

const highlightState = (state: string) => {
	if (state === InboundFlowTestingProcessState.TestPassed.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GREEN" }}>{state}</span>;
	} else if (
		state === InboundFlowTestingProcessState.NotImplemented.valueOf()
	) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{state}</span>;
	} else if (state === InboundFlowTestingProcessState.Timeout.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{state}</span>;
	} else if (state === InboundFlowTestingProcessState.TestFailed.valueOf()) {
		return <span style={{ fontWeight: "bold", color: "RED" }}>{state}</span>;
	} else {
		return <span style={{ fontWeight: "bold", color: "BLACK" }}>{state}</span>;
	}
};

export const InboundFlowTestingProcessesTable: React.FC<IProps> = (
	props: IProps
) => {
	const theme = useTheme();

	const navigate = useNavigate();

	const goToProcessDetails = (key: number) => {
		navigate("/inbound-flow-testing-processes/" + key);
	};

	return (
		<TableContainer>
			<Table size="small" data-cy="inbound-flows-test-processes-table">
				<TableHead>
					<TableRow sx={{ background: "#efefef" }}>
						<TableCell data-cy="inbound-flows-test-process-cell-id">
							Process ID
						</TableCell>
						<TableCell data-cy="inbound-flows-test-process-cell-submitted">
							Started At
						</TableCell>
						<TableCell data-cy="inbound-flows-test-process-cell-flow">
							Flow
						</TableCell>
						<TableCell data-cy="inbound-flows-test-process-cell-state">
							Status
						</TableCell>
						<TableCell data-cy="inbound-flows-test-process-cell-result">
							State
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.response.items.map((result: InboundFlowTestResponse) => (
						<TableRow
							key={result.key}
							data-cy={`flow-row-${result.key}`}
							sx={{
								"&:hover": {
									cursor: "pointer",
									backgroundColor: alpha(
										theme.palette.talos.colour.grey.dark,
										0.2
									),
								},
							}}
							onClick={() => goToProcessDetails(result.key)}
						>
							<TableCell>{result.key}</TableCell>
							<TableCell>
								{formatJavaStyleDateString(
									new Date(result.startDate).toISOString(),
									true
								)}
							</TableCell>
							<TableCell sx={{ minWidth: 70 }}>
								{unwrapString(result.variables.flowType)}
							</TableCell>
							<TableCell sx={{ minWidth: 120 }}>
								{highlightStatus(result.state)}
							</TableCell>
							<TableCell sx={{ minWidth: 150 }}>
								{highlightState(result.currentEvent)}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import {
	CamundaProcess,
	CamundaProcessStatus,
	InboundFlowTestingProcessState,
	MpanRelationshipProcessState,
} from "../../models/camunda";
import { formatJavaStyleDateString } from "../../utilities/string-fns";

interface IProps {
	process: CamundaProcess;
}
const highlightStatus = (state: string) => {
	if (state == CamundaProcessStatus.ACTIVE.valueOf()) {
		return { fontWeight: "bold", color: "ORANGE" };
	} else if (state == CamundaProcessStatus.INCIDENT.valueOf()) {
		return { fontWeight: "bold", color: "RED" };
	} else if (state == CamundaProcessStatus.COMPLETED.valueOf()) {
		return { fontWeight: "bold", color: "GREEN" };
	} else if (state == CamundaProcessStatus.CANCELED.valueOf()) {
		return { fontWeight: "bold", color: "GRAY" };
	}
};

const highlightState = (state: string) => {
	if (state === InboundFlowTestingProcessState.TestPassed.valueOf()) {
		return { fontWeight: "bold", color: "GREEN" };
	} else if (
		state === InboundFlowTestingProcessState.NotImplemented.valueOf()
	) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state === InboundFlowTestingProcessState.Timeout.valueOf()) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state === InboundFlowTestingProcessState.TestFailed.valueOf()) {
		return { fontWeight: "bold", color: "RED" };
	} else if (state == MpanRelationshipProcessState.Accepted.valueOf()) {
		return { fontWeight: "bold", color: "GREEN" };
	} else if (state == MpanRelationshipProcessState.AutoClosed.valueOf()) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state == MpanRelationshipProcessState.Closed.valueOf()) {
		return { fontWeight: "bold", color: "GRAY" };
	} else if (state == MpanRelationshipProcessState.ActionRequired.valueOf()) {
		return { fontWeight: "bold", color: "RED" };
	} else if (state == MpanRelationshipProcessState.WaitingPub020.valueOf()) {
		return { fontWeight: "bold", color: "ORANGE" };
	} else {
		return { fontWeight: "bold", color: "BLACK" };
	}
};

export const CamundaProcessDetails: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const expandProcessDetails = true;
	return (
		<Accordion defaultExpanded={expandProcessDetails}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="process-details-content"
				id="process-details-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Process Details
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="ID:"
						id="process-details-id"
						defaultValue={props.process.key}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Started At:"
						id="process-details-started-at"
						defaultValue={formatJavaStyleDateString(
							new Date(props.process.startDate).toISOString(),
							true
						)}
						variant="standard"
					/>
					<TextField
						InputProps={{
							readOnly: true,
							disableUnderline: true,
							sx: highlightStatus(props.process.state),
						}}
						fullWidth
						label="Status:"
						id="process-details-status"
						defaultValue={props.process.state}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="BPMN ID (Version):"
						id="process-details-bpmn-id"
						defaultValue={`${props.process.bpmnProcessId} (${props.process.processVersion})`}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Ended At:"
						id="process-details-ended-at"
						defaultValue={
							props.process.endDate
								? formatJavaStyleDateString(
										new Date(props.process.endDate).toISOString(),
										true
								  )
								: " "
						}
						variant="standard"
					/>
					<TextField
						InputProps={{
							readOnly: true,
							disableUnderline: true,
							sx: highlightState(props.process.currentEvent),
						}}
						fullWidth
						label="State:"
						id="process-details-state"
						defaultValue={props.process.currentEvent}
						variant="standard"
					/>
				</Stack>
			</AccordionDetails>
		</Accordion>
	);
};

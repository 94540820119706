import { useContext, useState } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
	Tab,
	Tabs,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { UseMutationResult, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import {
	closeException,
	resolveLinkedImportOrExportMpanException,
	resolveRelatedMpanException,
} from "../../api/camunda";
import { AuthContext } from "../../auth";
import {
	IS0020FlowFormValues,
	LinkedImportOrExportMpanS0020Request,
	MpanRelationshipProcessResponse,
	MpanRelationshipProcessState,
	Pub,
	Pub020EventCode,
	RelatedMpanS0020Request,
	ReletionshipType,
} from "../../models/camunda";
import { ProcessException } from "../../models/camunda/exception";
import { MeteringPointRelationshipUpdateRequest } from "../../models/camunda/mpan-relationship-update-request";
import { AlertContext, handleError } from "../../utilities";
import { AddS0020FlowForm } from "./add-s0020-flow-form";
import { CloseProcessForm } from "./close-process-form";

interface IProps {
	process: MpanRelationshipProcessResponse;
}

const resolvePrincipalMPANFromPub020 = (
	response: MpanRelationshipProcessResponse
): string => {
	if (response.variables.inboundPUB020) {
		try {
			const pub020 = JSON.parse(response.variables.inboundPUB020) as Pub;
			if (pub020.CommonBlock?.M1?.principalMPAN) {
				return pub020.CommonBlock?.M1?.principalMPAN;
			} else {
				return "";
			}
		} catch (error) {
			return "";
		}
	} else {
		return "";
	}
};

const mapEventCodeToRelationshipType = (eventCode: string): "" | "R" | "L" => {
	if (
		eventCode == Pub020EventCode.LinkedImportExportAdd.valueOf() ||
		eventCode == Pub020EventCode.LinkedImportExportCreate.valueOf() ||
		eventCode == Pub020EventCode.LinkedImportExportRemove.valueOf()
	) {
		return ReletionshipType.L.valueOf() as "R" | "L";
	} else if (
		eventCode == Pub020EventCode.RelatedCreate.valueOf() ||
		eventCode == Pub020EventCode.RelatedAdd.valueOf() ||
		eventCode == Pub020EventCode.RelatedRemove.valueOf()
	) {
		return ReletionshipType.R.valueOf() as "R" | "L";
	} else {
		return "";
	}
};

const resolveRelationshipTypeFromPub020 = (
	response: MpanRelationshipProcessResponse
): "" | "R" | "L" => {
	if (response.variables.inboundPUB020) {
		try {
			const pub020 = JSON.parse(response.variables.inboundPUB020) as Pub;
			if (pub020.CommonBlock?.S0?.eventCode) {
				return mapEventCodeToRelationshipType(
					pub020.CommonBlock?.S0?.eventCode
				);
			} else {
				return "";
			}
		} catch (error) {
			return "";
		}
	} else {
		return "";
	}
};

const resolvePrincipalMPANFromS0020Request = (
	response: MpanRelationshipProcessResponse
): string => {
	if (response.variables.newMeteringPointRelationshipUpdateRequest) {
		try {
			const request = JSON.parse(
				response.variables.newMeteringPointRelationshipUpdateRequest
			) as MeteringPointRelationshipUpdateRequest;
			return request.principalMpan;
		} catch (error) {
			return "";
		}
	} else {
		return "";
	}
};

const resolveRelationshipTypeFromS0020Request = (
	response: MpanRelationshipProcessResponse
): "" | "R" | "L" => {
	if (response.variables.newMeteringPointRelationshipUpdateRequest) {
		try {
			const request = JSON.parse(
				response.variables.newMeteringPointRelationshipUpdateRequest
			) as MeteringPointRelationshipUpdateRequest;
			return request.relationshipType as "R" | "L";
		} catch (error) {
			return "";
		}
	} else {
		return "";
	}
};

const resolveRelationshipType = (
	process: MpanRelationshipProcessResponse
): "" | "R" | "L" => {
	const relationshipTypeFromPub020 = resolveRelationshipTypeFromPub020(process);
	const relationshipTypeFromS020 =
		resolveRelationshipTypeFromS0020Request(process);
	if (relationshipTypeFromPub020 == "") {
		return relationshipTypeFromS020;
	} else {
		return relationshipTypeFromPub020;
	}
};

const resolvePrincipalMPAN = (
	process: MpanRelationshipProcessResponse
): string => {
	const mpanFromPub020 = resolvePrincipalMPANFromPub020(process);
	const mpanFromS020 = resolvePrincipalMPANFromS0020Request(process);
	if (mpanFromPub020 == "") {
		return mpanFromS020;
	} else {
		return mpanFromPub020;
	}
};

export const MpanRelationshipProcessAction: React.FC<IProps> = (
	props: IProps
) => {
	const authContext = useContext(AuthContext);

	const { setTalosAlert } = useContext(AlertContext);

	const theme = useTheme();

	const navigate = useNavigate();

	const process = props.process;

	const vars = process.variables;

	const s0020InitialValues: IS0020FlowFormValues = {
		principalMpan: resolvePrincipalMPAN(process),
		relationshipType: resolveRelationshipType(process),
		relationshipAction: "",
		mpanAction: "",
	};

	const exception: ProcessException = JSON.parse(
		vars.exception!
	) as ProcessException;

	const exceptionId = exception.id ? exception.id : " ";

	const expandFlowAction =
		props.process.currentEvent ==
		MpanRelationshipProcessState.ActionRequired.valueOf();

	const [tabValue, setTabValue] = useState<string>("s0020");

	const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabValue(newValue);
	};

	const closeProcessMutation: UseMutationResult<Boolean, Error> = useMutation(
		() => {
			return closeException(authContext, exceptionId);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: `Process ${process.key} has been closed`,
					severity: "success",
				});
				navigate("/mpan-relationship-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong closing Process. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});
				scrollTo(0, 0);
			},
		}
	);

	const resolveExceptionForLinkedImportOrExportMpan: UseMutationResult<
		Boolean,
		Error,
		LinkedImportOrExportMpanS0020Request
	> = useMutation(
		(values: LinkedImportOrExportMpanS0020Request) => {
			return resolveLinkedImportOrExportMpanException(
				authContext,
				exceptionId,
				values
			);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message:
						"LinkedImportOrExport MPAN Relationship Change has been received",
					severity: "success",
				});
				navigate("/mpan-relationship-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting LinkedImportOrExport MPAN Relationship Change form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				scrollTo(0, 0);
			},
		}
	);

	const resolveExceptionForRelatedMpan: UseMutationResult<
		Boolean,
		Error,
		RelatedMpanS0020Request
	> = useMutation(
		(values: RelatedMpanS0020Request) => {
			return resolveRelatedMpanException(authContext, exceptionId, values);
		},
		{
			onSuccess: () => {
				setTalosAlert({
					message: "Related MPAN Relationship Change has been received",
					severity: "success",
				});
				navigate("/mpan-relationship-processes");
			},
			onError: (e: Error) => {
				const errorId = handleError(e);

				setTalosAlert({
					message: `Something went wrong submitting Related MPAN Relationship Change form. Please contact IOPS Support, Ticket ID: ${errorId}`,
					severity: "error",
				});

				scrollTo(0, 0);
			},
		}
	);

	return (
		<Accordion defaultExpanded={expandFlowAction}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="mpan-relationship-process-action-content"
				id="mpan-relationship-process-action-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Action
				</Typography>
			</AccordionSummary>

			<AccordionDetails>
				<Box>
					<Tabs value={tabValue} onChange={handleTabChange} centered>
						<Tab value="s0020" label="Change MPAN Relationship" />
						<Tab value="close" label="Close Process" />
					</Tabs>
					{tabValue == "s0020" && (
						<AddS0020FlowForm
							s0020linkedImportOrExportMpanMutation={
								resolveExceptionForLinkedImportOrExportMpan
							}
							s0020relatedMpanMutation={resolveExceptionForRelatedMpan}
							formData={s0020InitialValues}
							disablePrincipalMpan={true}
							disableRelationshipType={true}
						/>
					)}
					{tabValue == "close" && (
						<Box>
							<Typography variant="body1" sx={{ mt: 1 }}>
								{
									"The Process can by manually closed, if Exception is already resolved or can be declided."
								}
							</Typography>
							<Typography variant="body1" sx={{ mt: 1 }}>
								{
									"The Process will be automatically closed, if no actions performed."
								}
							</Typography>
							<Divider sx={{ my: 2 }} />
							<CloseProcessForm closeProcessMutation={closeProcessMutation} />
						</Box>
					)}
				</Box>
			</AccordionDetails>
		</Accordion>
	);
};

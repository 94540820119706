import React from "react";

import { Box, SxProps, TextField, Theme } from "@mui/material";
import { Field, FormikProps } from "formik";

import { getValueFromForm } from "../../utilities";

interface TextBoxProps<T> {
	fieldName: string;
	label: string;
	form: FormikProps<T>;
	disabled?: boolean;
	sx?: SxProps<Theme>;
	overrideValue?: string | undefined;
	multiline?: boolean;
	minRows?: number;
	maxRows?: number;
	autoFocus?: boolean;
}

export const TalosTextBox = <T extends object>(
	props: TextBoxProps<T>
): JSX.Element => {
	const fieldValue =
		props.overrideValue || getValueFromForm(props.fieldName, props.form.values);
	const hasError = getValueFromForm(props.fieldName, props.form.errors);

	return (
		<Box className="form-row">
			<Field
				className="form-field"
				data-cy={props.fieldName}
				value={fieldValue || ""}
				as={TextField}
				label={props.label}
				sx={props.sx}
				helperText={hasError}
				name={props.fieldName}
				error={!!hasError}
				disabled={props.disabled}
				{...(props.multiline
					? {
							multiline: true,
							minRows: props.minRows,
							maxRows: props.maxRows,
					  }
					: {})}
				autoFocus={props.autoFocus}
			/>
		</Box>
	);
};

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import {
	ProcessException,
	ProcessExceptionNote,
} from "../../models/camunda/exception";
import { MpanRelationshipProcessState } from "../../models/camunda";

interface IProps {
	processState: string;
	exception: string;
}

const mayBeString = (value?: string) => {
	return value ? value : " ";
};

const mayBeNumber = (value?: number) => {
	return value ? value : " ";
};

export const CamundaProcessException: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const expandProcessException =
		props.processState ===
		MpanRelationshipProcessState.ActionRequired.valueOf();
	const exception: ProcessException = JSON.parse(
		props.exception
	) as ProcessException;

	const exceptionId = exception.id ? exception.id : " ";

	const hideNotes = exception.notes.length == 0;

	const exceptionNotes = exception.notes
		? exception.notes
				.map((n: ProcessExceptionNote) => {
					return n.created + ": " + n.note + "\n";
				})
				.join("")
		: " ";

	const hideAdditionalProperties = exception.additionalProperties
		? Object.keys(exception.additionalProperties).length == 0
		: false;

	const additionalProperties = exception.additionalProperties
		? Object.keys(exception.additionalProperties)
				.map((name: string) => {
					return name + ": " + exception.additionalProperties[name] + "\n";
				})
				.join("")
		: " ";

	return (
		<Accordion defaultExpanded={expandProcessException}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="process-exception-content"
				id="process-exception-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Exception Details
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{
							readOnly: true,
							disableUnderline: true,
							sx: { fontWeight: "bold" },
						}}
						fullWidth
						label="Exception ID:"
						id="process-exception-id"
						defaultValue={exceptionId}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Status:"
						id="process-exception-status"
						defaultValue={exception.status}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Priority:"
						id="process-exception-priority"
						defaultValue={exception.priority}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Exception Type:"
						id="process-exception-type"
						defaultValue={exception.exceptionType}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Category:"
						id="process-exception-category"
						defaultValue={exception.category}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Sub Category:"
						id="process-exception-sub-category"
						defaultValue={exception.subCategory}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Account Number:"
						id="process-exception-account-number"
						defaultValue={mayBeString(exception.account_number)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="MPAN:"
						id="process-exception-mpan"
						defaultValue={mayBeString(exception.mpan)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="MPRN:"
						id="process-exception-mprn"
						defaultValue={mayBeString(exception.mprn)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="From:"
						id="process-exception-from"
						defaultValue={mayBeString(exception.from)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="To:"
						id="process-exception-to"
						defaultValue={mayBeString(exception.to)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Total Value:"
						id="process-exception-total-value"
						defaultValue={mayBeNumber(exception.totalValue)}
						variant="standard"
					/>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Recoverable Value:"
						id="process-exception-recoverable-value"
						defaultValue={mayBeNumber(exception.recoverableValue)}
						variant="standard"
					/>
				</Stack>
				{!hideNotes && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Notes:"
							id="process-exception-notes"
							defaultValue={exceptionNotes}
							variant="standard"
						/>
					</Stack>
				)}
				{!hideAdditionalProperties && (
					<Stack
						direction="row"
						spacing={10}
						alignItems="flex-end"
						justifyContent="space-around"
					>
						<TextField
							multiline
							InputProps={{ readOnly: true, disableUnderline: true }}
							fullWidth
							label="Additional Properties:"
							id="process-exception-additional-properties"
							defaultValue={additionalProperties}
							variant="standard"
						/>
					</Stack>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

import { DropDownItem } from "../talos/ui-models";
import { CamundaProcess, FlowNode } from "./camunda-process";

export interface IS0020FlowFormValues {
	principalMpan: string;
	relationshipType: "L" | "R" | "";
	relationshipAction: "A" | "C" | "D" | "";
	mpanAction: "A" | "C" | "D" | "";
	exportMpan?: string;
	secondaryMpan?: string;
}

export interface LinkedExportMpan {
	mpanAction: string;
	exportMpan: String;
}

export interface LinkedImportOrExportMpanS0020Request {
	principalMpan: string;
	relationshipType: string;
	relationshipAction: string;
	linkedExportMpans: Array<LinkedExportMpan>;
}

export interface SecondaryMpan {
	mpanAction: string;
	secondaryMpan: String;
}
export interface RelatedMpanS0020Request {
	principalMpan: string;
	relationshipType: string;
	relationshipAction: string;
	secondaryMpans: Array<SecondaryMpan>;
}

export enum ReletionshipType {
	L = "L",
	R = "R",
}
export const RELATIONSHIP_TYPES: DropDownItem[] = [
	{ display: "Linked Import/Export MPAN Relationship", value: "L" },
	{ display: "Related MPAN Relationship", value: "R" },
];

export const RELATIONSHIP_ACTION: DropDownItem[] = [
	{ display: "A - Amend existing relationship", value: "A" },
	{ display: "C - Create new relationship", value: "C" },
	{ display: "D - Delete existing relationship", value: "D" },
];

export const MPAN_ACTION: DropDownItem[] = [
	{ display: "A - Add MPAN to relationship", value: "A" },
	{ display: "C - MPAN continues in relationship", value: "C" },
	{ display: "D - Delete MPAN from relationship", value: "D" },
];

export interface MpanRelationshipProcessPageResponse {
	items: MpanRelationshipProcessResponse[];
	total: number;
	searchAfter: Array<number>;
}

export interface MpanRelationshipProcessVariables {
	exception?: string;
	inboundPUB020?: string;
	newMeteringPointRelationshipUpdateRequest?: string;
}

export interface MpanRelationshipProcessResponse extends CamundaProcess {
	key: number;
	processVersion: number;
	bpmnProcessId: string;
	state: string;
	currentEvent: string;
	variables: MpanRelationshipProcessVariables;
	flowLog: Array<FlowNode>;
	startDate: number;
	endDate: number;
	bpmn?: string;
}

export enum MpanRelationshipProcessState {
	Accepted = "Accepted",
	Closed = "Closed",
	AutoClosed = "AutoClosed",
	ActionRequired = "Action Required",
	WaitingPub020 = "Waiting for PUB020",
}

export enum CamundaProcessStatus {
	ACTIVE = "ACTIVE",
	INCIDENT = "INCIDENT",
	COMPLETED = "COMPLETED",
	CANCELED = "CANCELED",
}

export enum Pub020EventCode {
	LinkedImportExportCreate = "[LinkedImportExportCreate]",
	LinkedImportExportAdd = "[LinkedImportExportAdd]",
	LinkedImportExportRemove = "[LinkedImportExportRemove]",
	RelatedCreate = "[RelatedCreate]",
	RelatedAdd = "[RelatedAdd]",
	RelatedRemove = "[RelatedRemove]",
}

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import { CamundaProcess, FlowNode } from "../../models/camunda";
import { formatJavaStyleDateString } from "../../utilities/string-fns";

interface IProps {
	process: CamundaProcess;
}

const highlightState = (state: string) => {
	if (state == "ACTIVE") {
		return <span style={{ fontWeight: "bold", color: "ORANGE" }}>{state}</span>;
	} else if (state == "INCIDENT") {
		return <span style={{ fontWeight: "bold", color: "RED" }}>{state}</span>;
	} else if (state == "COMPLETED") {
		return <span style={{ fontWeight: "bold", color: "GREEN" }}>{state}</span>;
	} else if (state == "TERMINATED") {
		return <span style={{ fontWeight: "bold", color: "GRAY" }}>{state}</span>;
	}
};

export const CamundaProcessFlowLog: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();
	const expandProcessFlowLog = false;
	const flowLogs: Array<FlowNode> = props.process.flowLog;

	const resolveState: (log: FlowNode) => string = (log: FlowNode) => {
		if (log.incident && log.incident) {
			return "INCIDENT";
		} else {
			return log.state ? log.state : " ";
		}
	};

	return (
		<Accordion defaultExpanded={expandProcessFlowLog}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="process-flow-log-content"
				id="process-flow-log-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					Process Flow Log
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<TableContainer>
					<Table size="small" data-cy="process-flow-log-table">
						<TableHead>
							<TableRow sx={{ background: "#efefef" }}>
								<TableCell data-cy="process-flow-log-cell-step">Step</TableCell>
								<TableCell data-cy="process-flow-log-cell-submitted">
									Type
								</TableCell>
								<TableCell data-cy="process-flow-log-cell-name">Name</TableCell>
								<TableCell data-cy="process-flow-log-cell-state">
									State
								</TableCell>
								<TableCell data-cy="process-flow-log-cell-started-at">
									Started At
								</TableCell>
								<TableCell data-cy="process-flow-log-cell-ended-at">
									Ended At
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{flowLogs.map((flowNode: FlowNode) => (
								<TableRow
									key={flowLogs.indexOf(flowNode) + 1}
									data-cy={`process-flow-log-row-${
										flowLogs.indexOf(flowNode) + 1
									}`}
									sx={{
										"&:hover": {
											backgroundColor: alpha(
												theme.palette.talos.colour.grey.dark,
												0.2
											),
										},
									}}
								>
									<TableCell>{flowLogs.indexOf(flowNode) + 1}</TableCell>
									<TableCell> {flowNode.type} </TableCell>
									<TableCell> {flowNode.flowNodeName} </TableCell>
									<TableCell>
										{" "}
										{highlightState(resolveState(flowNode))}{" "}
									</TableCell>
									<TableCell>
										{flowNode.startDate
											? formatJavaStyleDateString(
													new Date(flowNode.startDate).toISOString(),
													true
											  )
											: " "}
									</TableCell>
									<TableCell>
										{flowNode.endDate
											? formatJavaStyleDateString(
													new Date(flowNode.endDate).toISOString(),
													true
											  )
											: " "}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</AccordionDetails>
		</Accordion>
	);
};

import { IAuthContext } from "../../auth";
import { baseRequestOperations } from "../common/base-request";
import { MpxnPropertyInfoResponse } from "../../models/talos/cop-mpxn-property-info";

export enum API_TYPES {
	COP = "cop",
}

export const getMpanPropertyInfo = async (
	authContext: IAuthContext,
	mpan: string
): Promise<MpxnPropertyInfoResponse | null> => {
	const res = await baseRequestOperations(
		authContext,
		"GET",
		`${API_TYPES.COP}/mpans/property-information/${mpan}`,
		undefined,
		undefined
	);

	if (res.status === 404) {
		return null;
	} else if (res.status !== 200) {
		console.error(`Could not fetch property info for mpan: ${mpan}!`);
	}

	return res.data;
};

export const getMprnPropertyInfo = async (
	authContext: IAuthContext,
	mprn: string
): Promise<MpxnPropertyInfoResponse | null> => {
	const res = await baseRequestOperations(
		authContext,
		"GET",
		`${API_TYPES.COP}/mprns/gas-information/${mprn}`,
		undefined,
		undefined
	);

	if (res.status === 404) {
		return null;
	} else if (res.status !== 200) {
		console.error(`Could not fetch property info for mprn: ${mprn}!`);
	}

	return res.data;
};

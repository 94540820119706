import { MenuItem, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { Box, Stack } from "@mui/system";

import {
	INBOUND_FLOW_TYPES,
	InboundFlowFitlerFormValues,
} from "../../models/camunda";
import { TalosButton, TalosDropDown } from "../forms";
import { formValidationSchema } from "./add-inbound-flow-test-filter-form.validation-schema";

interface IProps {
	handleSubmit: (filter: InboundFlowFitlerFormValues) => void;
	formData: InboundFlowFitlerFormValues;
}

export const AddInboundFlowTestFilterForm: React.FC<IProps> = (
	props: IProps
) => {
	return (
		<Formik
			enableReinitialize
			onSubmit={props.handleSubmit}
			validationSchema={formValidationSchema}
			initialValues={props.formData}
			validateOnChange={true}
			validateOnMount={true}
		>
			{(form) => (
				<Form>
					<Stack
						direction="row"
						spacing={2}
						alignItems="center"
						justifyContent="space-between"
					>
						<Box minWidth={300}>
							<TextField
								InputProps={{ readOnly: true, disableUnderline: true }}
								fullWidth
								label="MHHS Spec Version"
								id="mhhsSpecVersion"
								defaultValue={"1.7.3"}
							/>
						</Box>
						<Box minWidth={300}>
							<TalosDropDown
								fieldName="inboundFlowType"
								label="MHHS Interface"
								form={form}
								menuItems={INBOUND_FLOW_TYPES.map((wl, idx) => (
									<MenuItem key={`inbound_flow_type${idx}`} value={wl.value}>
										{wl.display}
									</MenuItem>
								))}
							/>
						</Box>
						<TalosButton
							fieldName="form_submit"
							form={form}
							buttonText="Generate"
							loading={false}
							enableIfNotDirty={true}
						/>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

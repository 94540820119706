import { useContext, useEffect } from "react";

import { FormikProps, FormikValues } from "formik";

import { getMprnPropertyInfo } from "../../api/cop";
import { AuthContext } from "../../auth";
import { MpxnPropertyInfoResponse } from "../../models/talos/cop-mpxn-property-info";
import { TalosMprnField } from "./talos-mprn-field";

type PropertiesWithNumericValues<T> = {
	[P in keyof T]: T[P] extends number ? P : never;
}[keyof T];

export const TalosAutofillMprnField = <
	TFormValues extends FormikValues = FormikValues
>({
	label,
	form,
	fieldName,
	disabled,
	autofillFields,
}: {
	label: string;
	form: FormikProps<TFormValues>;
	fieldName: PropertiesWithNumericValues<FormikValues>;
	disabled?: boolean;
	autofillFields: (
		response: MpxnPropertyInfoResponse | null,
		form: FormikProps<TFormValues>
	) => void;
}) => {
	const authContext = useContext(AuthContext);
	const mprn = form.values[fieldName];

	useEffect(() => {
		let isFetching = false;
		let mprn = form.values[fieldName];

		if (mprn.toString().length >= 6 && mprn.toString().length <= 10) {
			getMprnPropertyInfo(authContext, mprn.toString()).then((data) => {
				if (!isFetching && data != null) {
					autofillFields(data, form);
				}
			});
		}
		return () => {
			isFetching = true;
		};
	}, [mprn]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<TalosMprnField
			label={label}
			fieldName={fieldName}
			form={form}
			disabled={disabled}
		/>
	);
};

import { Field, FormikProps, FormikValues } from "formik";
import { TextField } from "@mui/material";

type PropertiesWithNumericValues<T> = {
	[P in keyof T]: T[P] extends number ? P : never;
}[keyof T];

export const TalosMprnField = <
	TFormValues extends FormikValues = FormikValues
>({
	label,
	form,
	fieldName,
	disabled,
}: {
	label: string;
	form: FormikProps<TFormValues>;
	fieldName: PropertiesWithNumericValues<FormikValues>;
	disabled?: boolean;
}) => {
	return (
		<Field
			name={fieldName}
			data-cy={fieldName}
			label={label}
			disabled={disabled}
			as={TextField}
			type="number"
			onChange={(e: any) => {
				e.preventDefault();
				const { value } = e.target;
				if (value.toString().length === 0) {
					form.setFieldValue(fieldName, "");
				} else if (value.toString().length <= 10)
					form.setFieldValue(fieldName, Number(value));
			}}
			error={!!form.errors[fieldName]}
			helperText={form.errors[fieldName]}
		/>
	);
};

import { DropDownItem } from "../talos/ui-models";
import { CamundaProcess, FlowNode } from "./camunda-process";

export const MHHS_SPEC_VERSION: DropDownItem[] = [
	{ display: "1.2.3", value: "1.2.3" },
	{ display: "1.5.2", value: "1.5.2" },
	{ display: "1.7.2", value: "1.7.2" },
	{ display: "1.7.3", value: "1.7.3" },
];

export const INBOUND_FLOW_TYPES: DropDownItem[] = [
	{ display: "IF-001", value: "IF-001" },
	{ display: "IF-002", value: "IF-002" },
	{ display: "IF-006", value: "IF-006" },
	{ display: "IF-008", value: "IF-008" },
	{ display: "IF-009", value: "IF-009" },
	{ display: "IF-018", value: "IF-018" },
	{ display: "IF-020", value: "IF-020" },
	{ display: "IF-022", value: "IF-022" },
	{ display: "IF-023", value: "IF-023" },
	{ display: "IF-026", value: "IF-026" },
	{ display: "IF-032", value: "IF-032" },
	{ display: "IF-035", value: "IF-035" },
	{ display: "IF-036", value: "IF-036" },
	{ display: "IF-037", value: "IF-037" },
];

const MHHSSPECVERSION = MHHS_SPEC_VERSION.map((x) => x.value);
export type MhhsSpecVersion = (typeof MHHSSPECVERSION)[number];

const INBOUNDFLOWTYPES = INBOUND_FLOW_TYPES.map((x) => x.value);
export type InboundFlowType = (typeof INBOUNDFLOWTYPES)[number];

export interface InboundFlowFitlerFormValues {
	inboundFlowType: InboundFlowType;
}

export interface InboundFlowFormValues {
	pubMessage: string;
	mhhsSpecVersion: string;
	flowType: string;
}

export interface InboundFlowTestRequest {
	ifMessage: string;
}

export interface InboundFlowTestPagedResponse {
	items: InboundFlowTestResponse[];
	total: number;
	searchAfter: Array<number>;
}
export interface InboundFlowTestVariablesResponse {
	flowType?: string;
	pubMessage?: string;
	ifMessage?: string;
	result?: string;
}

export interface InboundFlowTestResponse extends CamundaProcess {
	key: number;
	processVersion: number;
	bpmnProcessId: string;
	state: string;
	currentEvent: string;
	variables: InboundFlowTestVariablesResponse;
	flowLog: Array<FlowNode>;
	startDate: number;
	endDate: number;
	bpmn?: string;
}

export enum InboundFlowTestingProcessState {
	TestPassed = "Test Passed",
	TestFailed = "Test Failed",
	Timeout = "Timeout",
	NotImplemented = "Not Implemented",
}

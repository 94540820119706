import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Stack,
	TextField,
	Typography,
	alpha,
	useTheme,
} from "@mui/material";

import {
	MPAN_ACTION,
	MpanRelationshipProcessState,
	RELATIONSHIP_ACTION,
	RELATIONSHIP_TYPES,
	ReletionshipType,
	MpanRelationshipProcessResponse,
} from "../../models/camunda";
import { MeteringPointRelationshipUpdateRequest } from "../../models/camunda/mpan-relationship-update-request";

interface IProps {
	process: MpanRelationshipProcessResponse;
}

const resolveRelationshipType = (type?: string) => {
	if (type) {
		const detailedType = RELATIONSHIP_TYPES.find((t) => t.value == type);
		return detailedType
			? detailedType.value + " - " + detailedType.display
			: " ";
	} else {
		return " ";
	}
};

const resolveRelationshipAction = (action?: string) => {
	if (action) {
		const detailedType = RELATIONSHIP_ACTION.find((t) => t.value == action);
		return detailedType ? detailedType.display : " ";
	} else {
		return " ";
	}
};

const resolveMpanAction = (action?: string) => {
	if (action) {
		const detailedType = MPAN_ACTION.find((t) => t.value == action);
		return detailedType ? detailedType.display : " ";
	} else {
		return " ";
	}
};

export const S0020RequestDetails: React.FC<IProps> = (props: IProps) => {
	const theme = useTheme();

	const vars = props.process.variables;

	const s0020Variable: string = vars.newMeteringPointRelationshipUpdateRequest!;

	const s0020Request: MeteringPointRelationshipUpdateRequest =
		JSON.parse(s0020Variable);

	const showExportMpan =
		s0020Request.relationshipType == ReletionshipType.L.valueOf();

	const showSecondaryMpan =
		s0020Request.relationshipType == ReletionshipType.R.valueOf();

	const expandS020Details =
		props.process.currentEvent ==
			MpanRelationshipProcessState.WaitingPub020.valueOf() ||
		props.process.currentEvent ==
			MpanRelationshipProcessState.ActionRequired.valueOf();

	return (
		<Accordion defaultExpanded={expandS020Details}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="s0020-request-details-content"
				id="s0020-request-details-header"
				sx={{
					backgroundColor: alpha(theme.palette.talos.colour.grey.dark, 0.2),
				}}
			>
				<Typography variant="h3" minWidth={900}>
					S0020 Details
				</Typography>
			</AccordionSummary>
			<AccordionDetails>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Principal MPAN:"
						id="s0020-request-details-spec"
						defaultValue={s0020Request.principalMpan}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Relationship Type:"
						id="s0020-request-relationship-type"
						defaultValue={resolveRelationshipType(
							s0020Request.relationshipType
						)}
						variant="standard"
					/>
				</Stack>
				<Stack
					direction="row"
					spacing={10}
					alignItems="flex-end"
					justifyContent="space-around"
					sx={{ mb: 3 }}
				>
					<TextField
						InputProps={{ readOnly: true, disableUnderline: true }}
						fullWidth
						label="Relationship Action:"
						id="s0020-request-relationship-action"
						defaultValue={resolveRelationshipAction(
							s0020Request.relationshipAction
						)}
						variant="standard"
					/>
				</Stack>
				{showExportMpan && (
					<>
						<Stack
							direction="row"
							spacing={10}
							alignItems="flex-end"
							justifyContent="space-around"
							sx={{ mb: 3 }}
						>
							<TextField
								InputProps={{ readOnly: true, disableUnderline: true }}
								fullWidth
								label="MPAN Action:"
								id="s0020-request-mapn-action"
								defaultValue={resolveMpanAction(
									s0020Request.linkedExportMpans[0].mpanAction
								)}
								variant="standard"
							/>
						</Stack>
						<Stack
							direction="row"
							spacing={10}
							alignItems="flex-end"
							justifyContent="space-around"
							sx={{ mb: 3 }}
						>
							<TextField
								InputProps={{ readOnly: true, disableUnderline: true }}
								fullWidth
								label="Export MPAN:"
								id="s0020-request-export-mapn"
								defaultValue={s0020Request.linkedExportMpans[0].exportMpan}
								variant="standard"
							/>
						</Stack>
					</>
				)}
				{showSecondaryMpan && (
					<>
						<Stack
							direction="row"
							spacing={10}
							alignItems="flex-end"
							justifyContent="space-around"
							sx={{ mb: 3 }}
						>
							<TextField
								InputProps={{ readOnly: true, disableUnderline: true }}
								fullWidth
								label="MPAN Action:"
								id="s0020-request-mapn-action"
								defaultValue={resolveMpanAction(
									s0020Request.secondaryMpans[0].mpanAction
								)}
								variant="standard"
							/>
						</Stack>
						<Stack
							direction="row"
							spacing={10}
							alignItems="flex-end"
							justifyContent="space-around"
							sx={{ mb: 3 }}
						>
							<TextField
								InputProps={{ readOnly: true, disableUnderline: true }}
								fullWidth
								label="Secondary MPAN:"
								id="s0020-request-secondary-mapn"
								defaultValue={s0020Request.secondaryMpans[0].secondaryMpan}
								variant="standard"
							/>
						</Stack>
					</>
				)}
			</AccordionDetails>
		</Accordion>
	);
};

import { getIn } from "formik";

import { MpxnPropertyInfoResponse } from "../models/talos/cop-mpxn-property-info";

export const mapCopFields = (
	copValues: MpxnPropertyInfoResponse | null,
	formValues: any,
	fieldsToMap: Record<string, string>,
	additionalFields?: Record<string, string | undefined>
) => {
	if (copValues == null) return formValues;

	let newValues = Object.fromEntries(
		Object.entries(fieldsToMap).map(([key, value]) => [
			key,
			getIn(copValues, value),
		])
	);

	return {
		...formValues,
		...newValues,
		...additionalFields,
	};
};

import { useCallback, useContext } from "react";

import {
	getRecentSettlementsRequests,
	SETTLEMENTS_READS_TYPES,
} from "../api/talos";
import { AuthContext } from "../auth";
import { BaseSettlementsReadsResponse } from "../models/talos/base-settlements-reads";
import { Page } from "../components/pagination/pageable";
import { useRecentRequestAlert } from "./alerts";
import { FVRResponse } from "../models/talos/force-validation-reads";
import { DMEXResponse } from "../models/talos/dmex";

const buildMessage = (
	readType: SETTLEMENTS_READS_TYPES,
	mpan: string,
	requests: Page<BaseSettlementsReadsResponse>
) => {
	if (readType == SETTLEMENTS_READS_TYPES.FVR) {
		const mostRecent = requests.items[0] as FVRResponse;
		return `A request was already made against MPAN ${mpan} on ${mostRecent.createdAt} for read type ${mostRecent.readType}`;
	} else {
		return `A request was already made against MPAN ${mpan} on ${
			(requests.items[0] as DMEXResponse).createdAt
		}.`;
	}
};

export const useRecentSettlementsAlert = (
	mpan: string,
	readType: SETTLEMENTS_READS_TYPES,
	days: number = 5
) => {
	const authContext = useContext(AuthContext);

	const getRecentRequest = useCallback(async () => {
		if (mpan.length < 13) return;

		return await getRecentSettlementsRequests(
			authContext,
			mpan,
			readType,
			days
		);
	}, [authContext, mpan, readType, days]);

	const getAlertMessage = useCallback(
		(results: Page<BaseSettlementsReadsResponse>) =>
			buildMessage(readType, mpan, results),
		[readType, mpan]
	);

	return useRecentRequestAlert(getRecentRequest, getAlertMessage, "warning");
};
